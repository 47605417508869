<template>
    <div>
        <div class="messageHead">
            <div class="left">消息</div>
            <i-icon color="#13161B" :size="20" name="icon-clean" @click.native="openMsg('msg')" />
        </div>
        <i-pull-refresh id="messageListContainer" class="messageListContent" v-model="refreshing" @refresh="onRefresh">
            <i-list v-model="loading" :finished="finished" finished-text="没有更多了" v-if="messageList.length" @load="onLoad">
                <div class="msgItem" v-for="(message, index) in messageList" :key="index" @click="goMsgDetail(message)">
                    <div class="icon-box">
                        <i-icon color="#fff" :size="17" name="icon-notice" />
                        <div class="messageDot" v-if="!message.readStatus"></div>
                    </div>
                    <div class="cnt-box">
                        <div class="title">{{ message.title }}</div>
                        <div class="time" v-if="message.sendTime">{{ message.sendTime.replace("T", " ") }}</div>
                        <div class="desc">尊敬的用户,{{ message.body }}</div>
                    </div>
                </div>
            </i-list>
            <empty-box v-if="!messageList.length" />
        </i-pull-refresh>
    </div>
</template>

<script>
import emptyBox from "@/components/iEmpty";

export default {
    name: "message",
    components: {
        emptyBox,
    },
    data() {
        return {
            messageList: [],
            pageIndex: 1,
            pageSize: 200,
            total: 0,
            loading: false,
            finished: false,
            refreshing: false,
            tenantId: "",
            messageListContainerScroll: 0,
        };
    },
    created() {
        this.tenantId = this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id");
        this.getList();
    },
    // keep-alive缓存后，重新进入缓存组件时触发
    activated() {
        document.getElementById("messageListContainer").scrollTop = this.messageListContainerScroll;
    },
    mounted() {
        document.addEventListener('touchmove', this.handleTouchmove, { passive: false })
    },
    beforeDestroy() {
        document.removeEventListener('touchmove',this.handleTouchmove,{ passive: false })
    },
    // keep-alive缓存后，离开缓存组件时触发
    deactivated() {},
    methods: {
        handleTouchmove(e) {
            if (!document.querySelector('#messageListContainer').contains(e.target)) {
                e.preventDefault();
            }
        },
        getList(clear) {
            let params = {
                channelId: "H5",
                noCount: false,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            };

            this.$service.Paging(params).then((res) => {
                if (clear == true) {
                    this.messageList = [];
                    this.refreshing = false;
                }
                if (res.success) {
                    this.messageList = this.messageList.concat(res.content.rows);
                    this.loading = false;
                    this.total = res.content.total;
                    this.$root.$eventHub.$emit("updateNotReadCount", res.content.notReadCount);
                }
            });
        },
        goMsgDetail(message) {
            this.messageListContainerScroll = document.getElementById("messageListContainer").scrollTop;
            this.$store.commit("setValue", {
                key: "messageDetail",
                value: message,
            });
            this.$router.push("messageDetail");
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.pageIndex = 1;
            this.messageList = [];
            this.messageListContainerScroll = 0;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.getList(true);
        },
        onLoad() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.finished = true;
            } else {
                this.pageIndex += 1;
                this.getList();
            }
        },
        openMsg() {
            this.$idialog
                .confirm({
                    title: "提示",
                    message: "是否确认全部已读?",
                })
                .then(() => {
                    // on confirm
                    this.allRead();
                })
                .catch(() => {
                    // on cancel
                });
        },
        allRead() {
            let params = { isReadAll: true, tenantId: this.tenantId };
            this.$service.Read(params).then((res) => {
                if (res.success) {
                    this.$itoast.success("操作成功！");
                    this.onRefresh();
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.messageHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.15rem 0.2rem 0.05rem 0.2rem;
    .left {
        font-size: 0.24rem;
        font-weight: 500;
        color: #13161b;
    }
    .messageHeadSvg {
        width: 0.2rem;
        height: 0.2rem;
        display: block;
    }
}

.messageListContent {
    margin-top: 0.1rem;
    height: calc(100vh - 1.235rem);
    padding: 0 0.18rem;
    box-sizing: border-box;
    overflow-y: scroll;
    img {
        left: 0.75rem;
    }
    .scrollLoading {
        margin-top: 0.3rem;
    }
    .msgItem {
        display: flex;
        margin-bottom: 0.12rem;
        .icon-box {
            width: 0.35rem;
            height: 0.35rem;
            background: var(--themeColor);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .messageDot {
                width: 0.08rem;
                height: 0.08rem;
                background: #ee0a24;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .cnt-box {
            margin-left: 0.18rem;
            max-width: 2.82rem;
            padding-bottom: 0.12rem;
            border-bottom: 1px solid #F5F5F5;
            .title {
                font-size: 0.16rem;
                font-weight: 500;
                color: #13161B;
            }
            .time {
                font-size: 0.1rem;
                font-weight: 500;
                color: #909399;
            }
            .desc {
                font-size: 0.14rem;
                font-weight: 500;
                color: #13161B;
                margin-top: 0.1rem;
            }
        }
    }
}
</style>
