var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "messageHead" },
        [
          _c("div", { staticClass: "left" }, [_vm._v("消息")]),
          _c("i-icon", {
            attrs: { color: "#13161B", size: 20, name: "icon-clean" },
            nativeOn: {
              click: function ($event) {
                return _vm.openMsg("msg")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "i-pull-refresh",
        {
          staticClass: "messageListContent",
          attrs: { id: "messageListContainer" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _vm.messageList.length
            ? _c(
                "i-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.messageList, function (message, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "msgItem",
                      on: {
                        click: function ($event) {
                          return _vm.goMsgDetail(message)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon-box" },
                        [
                          _c("i-icon", {
                            attrs: {
                              color: "#fff",
                              size: 17,
                              name: "icon-notice",
                            },
                          }),
                          !message.readStatus
                            ? _c("div", { staticClass: "messageDot" })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "cnt-box" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(message.title)),
                        ]),
                        message.sendTime
                          ? _c("div", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(message.sendTime.replace("T", " "))
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v("尊敬的用户," + _vm._s(message.body)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.messageList.length ? _c("empty-box") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }